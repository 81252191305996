/* eslint-disable @typescript-eslint/camelcase */
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import { MiddlewareFormInterface } from "../contracts/MiddlewareRequest";
import { MiddlewareDetail } from "@/domain/entities/Middleware";
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
export class RequestMiddlewareList {
    page = 1;
    limit = 10;
    search = "";
    source = "";
  
    constructor(fields?: Partial<RequestMiddlewareList>) {
      Object.assign(this, fields);
    }
  
    toQueryString(): string {
      return new QueryParamsEntities(this).queryString;
    }
}

export class PayloadFormMiddleware implements MiddlewareFormInterface {
  payload : MiddlewareDetail = new MiddlewareDetail();
  constructor(fields?: Partial<MiddlewareDetail>) {
    Object.assign(this.payload, fields);
  }
  public toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase({
      client_3lc: this.payload.client3lc,
      client_contact: this.payload.clientPhone,
      elexys_token: this.payload.webhookToken,
      ...this.payload,
    }));
}}
  