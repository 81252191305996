
import { Vue, Options } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { MiddlewareController } from "@/app/ui/controllers/MiddlewareController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { MiddlewareDetail } from "@/domain/entities/Middleware";
import { PayloadFormMiddleware } from "@/data/payload/api/MiddlewareApiRequest";
import { ApiStateData } from "@/domain/entities/Api";

@Options({
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  },
})
export default class MiddlewareAddForm extends Vue {
  get diction() {
    return this.isFormTypeAdd ? "Membuat" : "Mengubah";
  }

  apiGetData = new ApiStateData();
  mounted() {
    this.fetchDetail()
  }

  async fetchDetail() {
    if (!this.isFormTypeAdd) {
      this.apiGetData.loading = true;
      this.apiGetData.errorType = "";
      try {
        this.form = await MiddlewareController.getDetailMiddleware({
          type: "mapping-token",
          id: Number(this.$route.params.id)
        });
      } catch (err) {
        this.apiGetData.errorType = parsingErrorResponse(err).type;
      } finally {
        this.apiGetData.loading = false;
      }
    }
  }

  get titlePage() {
    return this.$route.meta.title;
  }

  leavePageConfirmation = false;
  saveConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  goBack() {
    const path = "/admin/middleware/mapping-token";
    this.$router.push(path);
  }
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }

  async onSaveForm() {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      await MiddlewareController.formMiddleware({
        method: this.isFormTypeAdd ? "post" : "put",
        type: "mapping-token",
        payload: new PayloadFormMiddleware(this.form)
      });
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: `Berhasil ${this.diction} Mapping Token!`,
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "badge-success",
          imageWidth: "36",
        })
      );
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, `Gagal ${this.diction} Mapping Token`, () =>
          this.onSaveForm()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  form: MiddlewareDetail = new MiddlewareDetail();
  passwordType = "password";

  changePasswordType(type: string) {
    if (type === "password") {
      this.passwordType = "text";
    } else {
      this.passwordType = "password";
    }    
  }

  get isFormValid() {
    return !!this.form.username &&
      !!this.form.password &&
      !!this.form.clientId
  }

  get isFormTypeAdd() {
    return this.$route.meta.formType === "add";
  }

  formatNumber(value: string) {
    return value.replace(/[^0-9]+/g, "")
  }
}
